import React, { useEffect, useState } from 'react';
import { FacebookLoginClient } from '@greatsumini/react-facebook-login';
import axios from 'axios';

const appId = '406796371876275';

const MyComponent = () => {
  const [userData, setUserData] = useState(null);
  const [userPosts, setUserPosts] = useState([]);

  useEffect(() => {
    window.fbAsyncInit = function () {
      FacebookLoginClient.init({ appId: '406796371876275', version: 'v18.0' });
    };

    // Cargar el SDK de Facebook de forma asíncrona
    (function (d, s, id) {
      var js, fjs = d.getElementsByTagName(s)[0];
      if (d.getElementById(id)) return;
      js = d.createElement(s); js.id = id;
      js.src = "https://connect.facebook.net/en_US/sdk.js";
      fjs.parentNode.insertBefore(js, fjs);
    }(document, 'script', 'facebook-jssdk'));
  }, []);

  const handleLogin = (response) => {
    if (response.status === 'connected') {
      // Hacer una solicitud a la API de Facebook para obtener información del usuario, incluyendo la foto de perfil
      axios.get(`https://graph.facebook.com/v18.0/${response.authResponse.userID}?fields=name,picture&access_token=${response.authResponse.accessToken}`)
        .then(userResponse => {
          // Actualizar el estado con la información del usuario, incluyendo la foto de perfil
          setUserData(userResponse.data);
        })
        .catch(error => {
          console.error('Error al obtener información del usuario:', error.response ? error.response.data : error.message);
        });

      // Hacer una solicitud a la API de Facebook para obtener los posts del usuario con información detallada
      axios.get(`https://graph.facebook.com/v18.0/${response.authResponse.userID}/posts?fields=message,full_picture,created_time,attachments&access_token=${response.authResponse.accessToken}`)
        .then(postsResponse => {
          // Actualizar el estado con los posts del usuario
          setUserPosts(postsResponse.data.data);
        })
        .catch(error => {
          console.error('Error al obtener los posts del usuario:', error.response ? error.response.data : error.message);
        });
    }
  };

  return (
    <div>
      <button className='w-full flex justify-center mt-20 bg-purple-100'
        onClick={() => {
          FacebookLoginClient.login(handleLogin, {
            scope: 'public_profile,user_posts',
          });
        }}
      >
        Login with Facebook
      </button>

      {userData && (
        <div className="mt-4 flex flex-col items-center justify-center">
          <img src={userData.picture.data.url} alt="Profile" className="rounded-xl h-10 w-10" />
          <div className="ml-4">
            <p>{userData.name}!</p>
          </div>
        </div>
      )}

      {userPosts.length > 0 && (
        <div className="mt-4">
          {userPosts.map(post => (
            <div key={post.id} className="mx-4 rounded-xl p-4 mb-4 border border-gray-300 bg-gray-100">
              {userData && userData.picture && userData.picture.data && userData.picture.data.url && (
                <div className="flex items-center">
                  <img src={userData.picture.data.url} alt="Profile" className="rounded-full h-10 w-10 object-cover" />
                  <p className="ml-2">{userData.name}</p>
                </div>
              )}
              <p>{post.message}</p>
              {post.attachments && post.attachments.data && post.attachments.data.length > 0 && (
                <img src={post.attachments.data[0].media.image.src} alt="Post Image" className="mt-2 rounded h-40 w-40 object-cover" />
              )}
              <p className="text-gray-500 text-xs">{new Date(post.created_time).toLocaleString()}</p>
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default MyComponent;
